import { PLANET_TYPE } from '@/domain/store/constants';
import { ContentBlock } from '@/shared/generated';
import { mapMediaLightToPackageCard } from '../util';

export const isMediaCountSufficient = (block: ContentBlock): boolean =>
  (block.__typename === 'CapyRecommendationBlock' &&
    block.paginatedMedia.records.length >= 10) ||
  block.__typename === 'SalesBlock' ||
  block.__typename === 'MediaSpotlightBlock';

export const mapStoreTopBlockItem = (
  block: ContentBlock,
  rowIndex: number,
  planet: PLANET_TYPE
) => {
  if (block?.__typename === 'SalesBlock') {
    return {
      id: block.id,
      imgSrcMobile: block.thumbnails[0]?.w2 ?? '',
      imgSrcDesktop: block.thumbnails[0]?.uw2 ?? '',
      imgAlt: block.name ?? '',
      kafkaProps: {
        target: 'store--saleBanner' as const,
        base_schema: 'user_click_dimension_1_default' as const,
        index: rowIndex,
        package_id: block.id,
        block_id: block.id,
      },
      href: `/store/${planet}/saledetail/${block.id}`,
    };
  }
  if (block.__typename === 'CapyRecommendationBlock') {
    return {
      id: block.id,
      title: block.name ?? '',
      cards:
        block.paginatedMedia.records?.map((record, columnIndex) => ({
          ...mapMediaLightToPackageCard(record),
          kafkaProps: {
            targetPrefix: 'store-titleList',
            base_schema: 'user_click_dimension_2_default' as const,
            row_index: rowIndex,
            column_index: columnIndex,
            block_id: block.id,
            package_id: record.id,
          },
        })) || [],
    };
  }
  if (block.__typename === 'MediaSpotlightBlock') {
    return {
      id: block.id,
      imgSrcMobile: block.spotlight.metadata.thumbnails[0]?.w2 ?? '',
      imgSrcDesktop: block.spotlight.metadata.thumbnails[0]?.uw2 ?? '',
      imgAlt: block.name ?? '',
      kafkaProps: {
        target: 'store--titleBanner' as const,
        base_schema: 'user_click_dimension_1_default' as const,
        index: rowIndex,
        package_id: block.id,
        block_id: block.id,
      },
    };
  }
};

export const getBlockIndexOffsets = ({
  hasSale,
  hasRanking,
  hasNew,
  pickUpLength,
}: {
  hasSale: boolean;
  hasRanking: boolean;
  hasNew: boolean;
  pickUpLength: number;
}): {
  SALE: number;
  RANKING: number;
  NEW: number;
  PICK_UP: number;
  POPULAR_TAGS: number;
  RECOMMEND: number;
} => {
  const saleIndex = 0;
  const rankingIndex = hasSale ? saleIndex + 1 : saleIndex;
  const newIndex = hasRanking ? rankingIndex + 1 : rankingIndex;
  const pickUpIndex = hasNew ? newIndex + 1 : newIndex;

  return {
    SALE: saleIndex,
    RANKING: rankingIndex,
    NEW: newIndex,
    PICK_UP: pickUpIndex,
    POPULAR_TAGS: pickUpIndex + pickUpLength,
    RECOMMEND: pickUpIndex + 1 + pickUpLength,
  };
};
