import { KafkaContext } from '@/domain/log/KafkaContext';
import { StoreLog } from '@/domain/log/__types__/store';
import { StoreSegmentedItemLog } from '@/domain/log/__types__/store-segmented-item';
import { TAG_SEPARATOR } from '@/domain/search/hooks/useSearchParams';
import Banner from '@/shared/components/Banner';
import Block, {
  PackageCardBlockProps,
  SaleBlockProps,
} from '@/shared/components/Block';
import Button from '@/shared/components/Button/Button';
import Tab from '@/shared/components/Control/Tab';
import Information from '@/shared/components/Information';
import DesktopHeader from '@/shared/components/Layout/DesktopHeader';
import PageLoading from '@/shared/components/Loading/PageLoading';
import MetaTags from '@/shared/components/MetaTags';
import SvodHint from '@/shared/components/SvodHint';
import TagList from '@/shared/components/TagList';
import { CARD_LIST_LIMIT } from '@/shared/constants/limit';
import { globalMessages } from '@/shared/constants/messages';
import {
  StoreMetaType,
  storeTopMetaMessage,
} from '@/shared/constants/meta/store/storeTop';
import {
  BeemiInfoListItemType,
  ContentBlock,
  useGetTopBlocksQuery,
} from '@/shared/generated';
import { useLoginState } from '@/shared/hooks/useLoginState';
import useTailwindBreakpoint from '@/shared/hooks/useTailwindBreakpoint';
import getLoginUrl from '@/shared/utils/getLoginUrl';
import { ApolloError } from '@apollo/client';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useContext, useEffect } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import { PLANET_ID, PLANET_TYPE } from '../constants';
import { messages as storeMessages } from '../messages';
import { mapMediaLightToPackageCard } from '../util';
import {
  getBlockIndexOffsets,
  isMediaCountSufficient,
  mapStoreTopBlockItem,
} from './util';

const messages = {
  title: defineMessage({
    id: 'store.title',
    defaultMessage: 'ストア',
  }),
  blockRankingSale: defineMessage({
    id: 'store.block.sale.title',
    defaultMessage: 'SALE',
  }),
  blockRankingTitle: defineMessage({
    id: 'store.block.ranking.title',
    defaultMessage: 'ランキング',
  }),
  blockRankingNew: defineMessage({
    id: 'store.block.new.title',
    defaultMessage: '新着',
  }),
  blockPopularTags: defineMessage({
    id: 'store.blockPopularTags.title',
    defaultMessage: `人気のジャンル ({planetName})`,
  }),
};

interface StoreTopBaseProps {
  planet: PLANET_TYPE;
}

const StoreTopBase: FC<StoreTopBaseProps> = ({ planet }) => {
  const router = useRouter();
  const { data, loading, error } = useGetTopBlocksQuery({
    variables: {
      planetId: PLANET_ID[planet],
      listLimit: CARD_LIST_LIMIT,
    },
  });

  const { isLoggedIn } = useLoginState();
  const isMd = useTailwindBreakpoint('md');

  const intl = useIntl();

  const PLANET_LABEL: Record<PLANET_TYPE, string> = {
    video: intl.formatMessage(storeMessages.planetTabVideo),
    amateur: intl.formatMessage(storeMessages.planetTabAmateur),
    anime: intl.formatMessage(storeMessages.planetTabAnime),
    vr: intl.formatMessage(storeMessages.planetTabVr),
  };

  const planetName = PLANET_LABEL[planet];

  const infoList = data?.beemiInfoList.filter(
    ({ ttl }) => new Date(ttl).getTime() > Date.now()
  );

  const saleDataRaw = data?.activeSaleBlocksV2.records.flatMap((saleBlock) => {
    if (
      saleBlock.__typename !== 'SalesBlock' ||
      saleBlock.paginatedMedia.pageInfo.totalRecordCount < 10
    ) {
      return [];
    }
    return [saleBlock];
  });

  const rankingDataRaw = data?.revenueRankingBlock?.paginatedMedia.records;

  const newDataRaw = data?.newMediaRankingBlock?.paginatedMedia.records;

  const pickUpDataRaw = (
    data?.pickupBlockV2.records as ContentBlock[] | undefined
  )?.filter(isMediaCountSufficient);

  const BLOCK_INDEX_OFFSETS = getBlockIndexOffsets({
    hasSale: !!saleDataRaw?.length,
    hasRanking: !!rankingDataRaw?.length,
    hasNew: !!newDataRaw?.length,
    pickUpLength: pickUpDataRaw?.length ?? 0,
  });

  const saleData: SaleBlockProps['cards'] =
    data?.activeSaleBlocksV2.records.flatMap((saleBlock, index) => {
      if (
        saleBlock.__typename !== 'SalesBlock' ||
        saleBlock.paginatedMedia.pageInfo.totalRecordCount < 10
      ) {
        return [];
      }
      return [
        {
          id: saleBlock.id,
          href: `/store/${planet}/saledetail/${saleBlock.id}`,
          imgSrcMobile: saleBlock.thumbnails[0]?.w1 ?? '',
          imgSrcDesktop: saleBlock.thumbnails[0]?.w2 ?? '',
          imgAlt: saleBlock.name ?? '',
          kafkaProps: {
            target: 'store--saleBanner',
            block_id: saleBlock.id,
            index: index,
          },
        },
      ];
    });

  const rankingData: PackageCardBlockProps['cards'] =
    rankingDataRaw?.map((item, index) => ({
      ...mapMediaLightToPackageCard(item),
      ranking: index + 1,
      kafkaProps: {
        targetPrefix: 'store-rankingList',
        base_schema: 'user_click_dimension_2_default',
        row_index: BLOCK_INDEX_OFFSETS.RANKING,
        column_index: index,
        package_id: item.id,
        block_id: data?.revenueRankingBlock?.id ?? '',
      },
    })) || [];

  const newData: PackageCardBlockProps['cards'] =
    newDataRaw?.map((item, index) => ({
      ...mapMediaLightToPackageCard(item),
      kafkaProps: {
        targetPrefix: 'store-newList',
        base_schema: 'user_click_dimension_2_default',
        row_index: BLOCK_INDEX_OFFSETS.NEW,
        column_index: index,
        package_id: item.id,
        block_id: data?.newMediaRankingBlock?.id ?? '',
      },
    })) || [];

  const popularTagsData = data?.popularContentTagsV2.records.map(
    (tag, index) => ({
      ...tag,
      kafkaProps: {
        targetPrefix: 'store-popularGenre',
        base_schema: 'user_click_dimension_2_default' as const,
        column_index: index,
        content_type: tag.contentType,
        row_index: BLOCK_INDEX_OFFSETS.POPULAR_TAGS,
        tag_id: tag.id,
      },
    })
  );

  const pickUpArray = pickUpDataRaw?.map((item, index) =>
    mapStoreTopBlockItem(item, index + BLOCK_INDEX_OFFSETS.PICK_UP, planet)
  );

  const recommendArray = (
    data?.recommendedBlocksV2.records as ContentBlock[] | undefined
  )
    ?.filter(isMediaCountSufficient)
    .map((item, index) =>
      mapStoreTopBlockItem(item, index + BLOCK_INDEX_OFFSETS.RECOMMEND, planet)
    );

  const { client } = useContext(KafkaContext);

  useEffect(() => {
    client?.trackUser<StoreLog>('store', 'view', 'user_view_page_default', {
      segmented_id: planet,
    });
  }, [client, planet]);

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    throw new ApolloError(error);
  }

  const metaPlanetType = (router.query.planet ?? 'top') as StoreMetaType;

  return (
    <>
      <DesktopHeader title={intl.formatMessage(messages.title)} />
      <div className="pt-12 space-y-10 md:mt-12">
        <MetaTags
          title={intl.formatMessage(storeTopMetaMessage[metaPlanetType].title)}
          description={intl.formatMessage(
            storeTopMetaMessage[metaPlanetType].description
          )}
          canonicalLink={`/store/${planet}`}
        />
        {infoList && infoList?.length > 0 && (
          <div className="mx-5 space-y-2 md:mx-12">
            {infoList.map(({ title, url, type }) => (
              <Information
                key={title}
                url={url}
                type={type ?? BeemiInfoListItemType.Info}
              >
                {title}
              </Information>
            ))}
          </div>
        )}
        {!isLoggedIn && !isMd && (
          <section className="flex p-1 mx-5 rounded-md gap-x-1 bg-purple/10">
            <Button
              text={intl.formatMessage(globalMessages.newMember)}
              component="a"
              href={process.env.NEXT_PUBLIC_REGISTER_WITH_MONTHLY_PLAN_URL}
              appendClassName="flex-grow"
            />
            <Button
              text={intl.formatMessage(globalMessages.login)}
              variant="SECONDARY"
              component="a"
              appendClassName="flex-grow"
              href={getLoginUrl()}
            />
          </section>
        )}
        <nav className="grid w-full grid-flow-col grid-cols-4 px-5 md:block md:px-12">
          {Object.keys(PLANET_LABEL).map((planetKey) => {
            return (
              <Link
                passHref
                key={planetKey}
                href={`/store/${planetKey}`}
                legacyBehavior
              >
                <Tab
                  component="a"
                  size="DEFAULT"
                  isActive={planet === planetKey}
                  onClick={() => {
                    client?.trackUser<StoreSegmentedItemLog>(
                      'store-segmented-item',
                      'click',
                      'user_click_dimension_0_default',
                      {
                        segmented_id: planetKey as PLANET_TYPE,
                      }
                    );
                  }}
                >
                  {PLANET_LABEL[planetKey as PLANET_TYPE]}
                </Tab>
              </Link>
            );
          })}
        </nav>
        {saleData && saleData.length > 0 && (
          <Block
            key="sale"
            type="SALE"
            title={intl.formatMessage(messages.blockRankingSale)}
            showMoreLink={`/store/${planet}/sale`}
            kafkaProps={{
              targetPrefix: 'store-saleList',
              index: BLOCK_INDEX_OFFSETS.SALE,
            }}
            cards={saleData}
            data-testid="store-saleList"
          />
        )}
        {rankingData && rankingData.length > 0 && (
          <Block
            key="ranking"
            type="PACKAGE_LARGE"
            title={intl.formatMessage(messages.blockRankingTitle)}
            showMoreLink={`/store/${planet}/ranking`}
            kafkaProps={{
              targetPrefix: 'store-rankingList',
              index: BLOCK_INDEX_OFFSETS.RANKING,
              block_id: data?.revenueRankingBlock?.id ?? '',
            }}
            cards={rankingData}
            data-testid="store-rankingList"
          />
        )}
        {newData && newData.length > 0 && (
          <Block
            key="new"
            type="PACKAGE_SMALL"
            title={intl.formatMessage(messages.blockRankingNew)}
            showMoreLink={`/store/${planet}/new`}
            kafkaProps={{
              targetPrefix: 'store-newList',
              index: BLOCK_INDEX_OFFSETS.NEW,
              block_id: data?.newMediaRankingBlock?.id ?? '',
            }}
            cards={newData}
            data-testid="store-newList"
          />
        )}
        {pickUpArray?.map((pickup, index) => {
          if (pickup?.imgSrcMobile) {
            return (
              <Banner
                {...pickup}
                key={`${index}_${pickup.id}`}
                className="px-5 md:px-12"
                size="FULL"
                href={
                  pickup.href ?? {
                    pathname: router.pathname,
                    query: {
                      ...router.query,
                      pid: pickup.id,
                    },
                  }
                }
              />
            );
          }
          if (pickup?.title) {
            return (
              <Block
                key={`${index}_${pickup.id}`}
                type="PACKAGE_SMALL"
                title={pickup.title}
                showMoreLink={`/store/${planet}/altdetail/${pickup.id}`}
                kafkaProps={{
                  targetPrefix: 'store-titleList',
                  index: index + BLOCK_INDEX_OFFSETS.PICK_UP,
                  block_id: pickup.id,
                }}
                cards={pickup.cards}
                data-testid="store-pickupTitleList"
              />
            );
          }
        })}
        <section
          data-testid="SvodHint"
          className="mx-5 mb-10 mt-9 md:mx-12 md:my-10"
        >
          <SvodHint />
        </section>
        {popularTagsData && (
          <section className="px-5 pt-5 pb-6 bg-purple/10 md:px-12 md:pt-6 md:pb-8">
            <TagList
              tags={popularTagsData}
              dataTestId="store-popularGenre-tag"
              title={intl.formatMessage(
                {
                  id: 'store.popular',
                  defaultMessage: `人気のジャンル ({planetName})`,
                },
                {
                  planetName,
                }
              )}
              generateHref={(id, contentType) => ({
                pathname: '/search',
                query: `tag=${PLANET_ID[planet]}${TAG_SEPARATOR}PLANET&tag=${id}${TAG_SEPARATOR}${contentType}`,
              })}
            />
          </section>
        )}
        {recommendArray?.map((recommend, index) => {
          if (!recommend) return null;
          if (recommend.imgSrcMobile) {
            return (
              <Banner
                {...recommend}
                key={`${index}_${recommend.id}`}
                className="px-5 md:px-12"
                size="FULL"
                href={{
                  pathname: router.pathname,
                  query: {
                    ...router.query,
                    pid: recommend.id,
                  },
                }}
              />
            );
          }
          if (recommend.title) {
            return (
              <Block
                key={`${index}_${recommend.id}`}
                type="PACKAGE_SMALL"
                title={recommend.title}
                showMoreLink={`/store/${planet}/altdetail/${recommend.id}`}
                cards={recommend.cards.slice(0, CARD_LIST_LIMIT)}
                kafkaProps={{
                  targetPrefix: 'store-titleList',
                  index: index + BLOCK_INDEX_OFFSETS.RECOMMEND,
                  block_id: recommend.id,
                }}
                data-testid="store-recommendTitleList"
              />
            );
          }
        })}
      </div>
    </>
  );
};

export default StoreTopBase;
