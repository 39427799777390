import { PLANET_TYPE } from '@/domain/store/constants';
import { defineMessage } from 'react-intl';

export type StoreMetaType = PLANET_TYPE | 'top';

export interface MetaMessage {
  title: MessageType;
  description: MessageType;
}

export interface MessageType {
  id: string;
  defaultMessage: string;
}

export const storeTopMetaMessage: Record<StoreMetaType, MetaMessage> = {
  top: {
    title: defineMessage({
      id: 'store.meta.top.title',
      defaultMessage:
        'アダルト動画が31日間無料で見放題！人気AV女優・素人・エロアニメも！ | BEEMI（ビーミ）',
    }),
    description: defineMessage({
      id: 'store.meta.top.description',
      defaultMessage:
        '<31日間無料トライアル実施中>人気AV女優・ガチ素人・エロアニメなどアダルト動画を観るならBEEMI。プレステージ・MGS動画・FALENOなど人気メーカー作品も多数配信中。BEEMIなら安心安全に、今すぐスマホ・PCで高画質に楽しめる！',
    }),
  },
  video: {
    title: defineMessage({
      id: 'store.meta.video.title',
      defaultMessage:
        'アダルト女優のエロ動画が31日間無料で見放題！最新AVから人気作まで | BEEMI（ビーミ）',
    }),
    description: defineMessage({
      id: 'store.meta.video.description',
      defaultMessage:
        '<31日間無料トライアル実施中>人気AV女優・ガチ素人・エロアニメなどアダルト動画を観るならBEEMI。プレステージ・MGS動画・FALENOなど人気メーカー作品も多数配信中。BEEMIなら安心安全に、今すぐスマホ・PCで高画質に楽しめる！',
    }),
  },
  amateur: {
    title: defineMessage({
      id: 'store.meta.amateur.title',
      defaultMessage:
        '素人のアダルト動画が31日間無料で見放題！エロい最新AVが盛りだくさん | BEEMI（ビーミ）',
    }),
    description: defineMessage({
      id: 'store.meta.amateur.description',
      defaultMessage:
        '<31日間無料トライアル実施中>素人のアダルト動画を見放題で配信中！人気シリーズから話題の作品まで。プレステージ・MGS動画・FALENOなど人気メーカー作品も多数配信中。BEEMIなら安心安全に、今すぐスマホ・PCで高画質に楽しめる！',
    }),
  },
  anime: {
    title: defineMessage({
      id: 'store.meta.anime.title',
      defaultMessage:
        'エロアニメ動画が31日間無料で見放題！人気のアダルト動画が盛りだくさん | BEEMI（ビーミ）',
    }),
    description: defineMessage({
      id: 'store.meta.anime.description',
      defaultMessage:
        '<31日間無料トライアル実施中>エロアニメ動画を見放題で配信中！美少女・ロリ・人妻などあらゆるフェチにお応え！BEEMIなら安心安全に、今すぐスマホ・PCで高画質に楽しめる！',
    }),
  },
  vr: {
    title: defineMessage({
      id: 'store.meta.vr.title',
      defaultMessage:
        'アダルトVR動画が31日間無料で見放題！最新AVから人気作まで | BEEMI（ビーミ）',
    }),
    description: defineMessage({
      id: 'store.meta.vr.description',
      defaultMessage:
        '<31日間無料トライアル実施中>アダルトVR動画を配信中！美少女・ロリ・人妻などあらゆるフェチにお応え！BEEMIなら安心安全に、今すぐアダルト動画を楽しめる！',
    }),
  },
};
