import { BeemiInfoListItemType } from '@/shared/generated';
import classNames from 'classnames';
import Link from 'next/link';
import { FC, ReactNode } from 'react';
import Icon from '../Icon';

interface Props {
  url: string;
  type: BeemiInfoListItemType;
  children?: ReactNode;
}

const styles = {
  [BeemiInfoListItemType.Info]: 'bg-purple/10 text-purple/80',
  [BeemiInfoListItemType.Warning]: 'border border-error text-error',
};

const Information: FC<Props> = ({ children, url, type }) => {
  return (
    <Link
      href={url}
      className={classNames(
        'flex items-center w-full p-4 rounded md:p-3 md:pl-4 box-border h-14 text-caption',
        styles[type]
      )}
    >
      <div className="flex w-4.5 h-4.5 md:h-6 md:w-6">
        <Icon
          name={
            type === BeemiInfoListItemType.Warning
              ? 'CAUTION_ICON'
              : 'INFO_ICON'
          }
          size="FULL"
        />
      </div>
      <span className="ml-1">{children}</span>
      <div className="flex w-6 h-6 ml-auto md:h-8 md:w-8">
        <Icon name="HALF_ARROW_RIGHT" size="FULL" />
      </div>
    </Link>
  );
};

export default Information;
